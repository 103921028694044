import { FC, useContext } from "react";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import { formatAppNameText } from "@finbackoffice/fe-core";
import classnames from "classnames";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import Translate from "components/base/translate/Translate";
import { Svg } from "components/base/svg/Svg";
import Video from "components/base/video/Video";
import { CasinoGameItemContext } from "../../CasinoGameItem";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const {
        game,
        handlePlayNow,
        gameName,
        isUserLoggedIn,
        isFavorite,
        handleFavoriteClick,
        handlePlayForFun,
        showName,
        fallbackSrc,
        onReady,
        hidePlayButtons,
        tagsMemo,
        isVideo,
        handleVideoError,
        gameImgSrc,
        onFallbackHandler,
    } = useContext(CasinoGameItemContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { t } = useTranslation(TranslationScopes.Casino);

    return (
        <div className={classnames("casino-game-item", styles.gameItem)}>
            <span className={styles.gameLabel}>
                {tagsMemo.map((tag) => (
                    <Svg
                        key={tag}
                        src={`/common/casino/compact/icon-casino-${tag.toLowerCase()}.svg`}
                        wrapper="span"
                        className={classnames(
                            "svg-icon",
                            styles[`base-icon-casino-${tag.toLowerCase()}`],
                        )}
                    />
                ))}
            </span>
            <div
                onClick={() => {
                    if (hidePlayButtons) {
                        handlePlayNow();
                    }
                }}>
                {game.tags.includes(CasinoGameTag.DropsAndWins) && (
                    <Svg
                        src={`/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/common/icon-casino-drops-and-wins.svg`}
                        wrapper="span"
                        className={classnames("svg-icon", styles.casinoDropsAndWins)}
                    />
                )}
                {isVideo ? (
                    <Video src={gameImgSrc} onError={handleVideoError} onLoad={() => onReady?.()} />
                ) : (
                    <Img
                        source={gameImgSrc}
                        alt={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        title={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        width={220}
                        height={260}
                        fallbackSrc={fallbackSrc || game.image_portrait}
                        onFallback={onFallbackHandler}
                        onLoad={() => onReady?.()}
                    />
                )}
                {!hidePlayButtons && (
                    <div className={styles.btnsContainer}>
                        <Button
                            type="button"
                            variant="secondary"
                            className={styles.playNowBtn}
                            onClick={handlePlayNow}>
                            <Translate tid="casino_play_now" namespace={TranslationScopes.Casino} />
                        </Button>
                        {game.play_for_fun_supported && (
                            <Button
                                className={styles.tryButton}
                                type="button"
                                onClick={handlePlayForFun}>
                                <Translate tid="casino_try" namespace={TranslationScopes.Casino} />
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <section className={styles.gameItemName}>
                {COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Oddsgate && (
                    <span className={classnames("game-item-name-blur-bg", styles.bluredBg)}>
                        <span />
                    </span>
                )}
                <div>
                    {showName && <span title={game.name}>{game.name}</span>}
                    {game.category}

                    {isUserLoggedIn && (
                        <i
                            className={classnames(styles.unstarred, isFavorite && styles.starred)}
                            onClick={handleFavoriteClick}
                        />
                    )}
                </div>
            </section>
        </div>
    );
};

export default Compact;
