import { FC, useContext } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import classnames from "classnames";
import { useTranslation } from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Img from "components/base/img/Img";
import Translate from "components/base/translate/Translate";
import Video from "components/base/video/Video";
import { CasinoGameItemContext } from "../../CasinoGameItem";
import styles from "./squared.module.sass";

const Squared: FC = () => {
    const {
        game,
        handlePlayNow,
        gameName,
        isUserLoggedIn,
        isFavorite,
        handleFavoriteClick,
        handlePlayForFun,
        fallbackSrc,
        onReady,
        isVideo,
        handleVideoError,
        gameImgSrc,
        onFallbackHandler,
        isSlots,
    } = useContext(CasinoGameItemContext);
    const { t } = useTranslation(TranslationScopes.Casino);

    return (
        <div className={classnames("casino-game-item", styles.gameItem)}>
            <div>
                {isVideo ? (
                    <Video src={gameImgSrc} onError={handleVideoError} onLoad={() => onReady?.()} />
                ) : (
                    <Img
                        source={gameImgSrc}
                        alt={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        title={`${t(`casino_${game.type.replaceAll("-", "_")}`)} ${gameName}`}
                        width={0}
                        height={0}
                        style={{ width: 140, height: "auto" }}
                        fallbackSrc={fallbackSrc || game.image_portrait}
                        onFallback={onFallbackHandler}
                        onLoad={() => onReady?.()}
                    />
                )}
                {isUserLoggedIn && (
                    <i
                        className={classnames(styles.unstarred, isFavorite && styles.starred)}
                        onClick={handleFavoriteClick}
                    />
                )}
            </div>
            <p className={styles.gameName} title={game.name}>
                <span>{game.name}</span>
            </p>
            <Button
                type="button"
                variant="secondary"
                className={styles.playNowBtn}
                onClick={handlePlayNow}>
                <Translate tid="casino_play_now" namespace={TranslationScopes.Casino} />
            </Button>
            {isSlots && (
                <Button
                    className={styles.tryButton}
                    type="button"
                    onClick={handlePlayForFun}
                    disabled={!game.play_for_fun_supported}>
                    <Translate tid="casino_try" namespace={TranslationScopes.Casino} />
                </Button>
            )}
        </div>
    );
};

export default Squared;
